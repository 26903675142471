//import { normalize } from "normalizr";
import appConfig from "../config/application";

export const callApiEndpoint = (endpoint, options) => {
  let status;
  return fetch(endpoint, options)
    .then(res => {
      status = res.status;
      return res.json();
    })
    .then(
      (result) => {
        if (status !== 200) {
          return {error: result.message};
        }
        return {response: result};
      },
      (error) => {
        console.log("Looks like there was a problem: \n", error);
      }
    );
};

// User Login
export const userLogin = (filters) => {
  const endpoint = `${appConfig.apiBackendURI}/auth/sign_in`;
  const options = {
    headers: Object.assign(
      {
        "Content-Type": "application/json",
      },
    ),
    method: "POST",
    body: JSON.stringify(filters),
  }

  return callApiEndpoint(endpoint, options);
};

export const userResetPassword = (values) => {
  const endpoint = `${appConfig.apiBackendURI}/auth/reset_password`;
  const options = {
    headers: Object.assign(
      {
        "Content-Type": "application/json",
      },
    ),
    method: "POST",
    body: JSON.stringify(values),
  }

  return callApiEndpoint(endpoint, options);
};