export const SECONDS_IN_MINUTES = 60;
export const MILLISECONDS_IN_SECONDS = 1000;

export const formatDate = (dateInput) => {
    if(dateInput.toString().length < 11){
        dateInput = dateInput * MILLISECONDS_IN_SECONDS;
    }
    let dateTime = new Date(dateInput);
    dateTime = new Date(dateTime.getTime() + (dateTime.getTimezoneOffset() * SECONDS_IN_MINUTES * MILLISECONDS_IN_SECONDS));
    return new Date(dateInput).toISOString().split('T')[0] + " " + (dateTime.getHours()<10?'0':'') + dateTime.getHours() + ":" + (dateTime.getMinutes()<10?'0':'') + dateTime.getMinutes();
};
