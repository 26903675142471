import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ExportCSV from "../components/ExportCSV";
import csvDataSelector from "../selectors/csvDataSelector";
import { buildURI } from "../helpers/csv";

class ExportCsvContainer extends Component {
  render() {
    const { data, disabled } = this.props;
    const href = buildURI(data, true, undefined, ",");

    return (
      <ExportCSV
        title="Export CSV"
        href={href}
        download="solution"
        disabled={disabled}
        onClick={e => {
          if (disabled) {
            e.preventDefault();
          }
        }}
      />
    );
  }
}

ExportCsvContainer.propTypes = {
  data: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    data: csvDataSelector(state),
  };
}

export default connect(mapStateToProps)(ExportCsvContainer);
