import polyline from "@mapbox/polyline";
import * as actions from "../global/actionTypes";
import _ from "lodash";

const initState = {
  data: {},
};

export default function(state = initState, action = {}) {
  switch (action.type) {
  case actions.FETCH_JOB_SUCCESS:
    const { polylines } = action.response.data.output || {};
    const newData = {};
    Object.keys(polylines || {}).forEach((fleetId) => {

      if (polylines[fleetId] && polylines[fleetId][0]) {
        const all_coords = [];
        console.log("polylines[fleetId].length " + polylines[fleetId].length);
        for(let index=0; index < polylines[fleetId].length; index++){
          const coords = polyline.decode(polylines[fleetId][index]);
          coords.forEach((coord) => {
            all_coords.push(coord);
          });
        }

        newData[`${action.jobId}-${fleetId}`] = _.map(all_coords, coord => ({
          lat: coord[0],
          lng: coord[1],
        }));
      }
    });
    return {
      data: {
        ...state.data,
        ...newData,
      },
    };
  default:
    return state;
  }
}


