import * as actions from "../global/actionTypes";

const initState = {
  fleetIds: [],
};

export default function(state = initState, action = {}) {
  switch (action.type) {
  case actions.UPDATE_FILTER_STATE:
    return {
      fleetIds: action.filter.fleetIds,
    };
  case actions.FETCH_JOB_REQUEST:
    return {
      fleetIds: [],
    };
  case actions.FETCH_JOB_SUCCESS:
    const fleetIds = !!action.response.data.input ? Object.keys(action.response.data.input.fleet) : [];

    return {
      fleetIds: [...fleetIds, "unserved"],
    };
  default:
    return state;
  }
}
