import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Filter from "../components/Filter";
import fleetIdsSelector from "../selectors/fleetIdsSelector";
import { updateFilterState } from "../actions/filter";

class FilterContainer extends Component {
  render() {
    return (
      <Filter
        fleetIds={this.props.fleetIds}
        selectedFleetIds={this.props.selectedFleetIds}
        activeFleetsCount={this.props.activeFleetsCount}
        fleetsCount={this.props.fleetsCount}
        visitsCount={this.props.visitsCount}
        updateFilterState={this.props.updateFilterState}
      />
    );
  }
}

FilterContainer.propTypes = {
  activeFleetsCount: PropTypes.number.isRequired,
  fleetsCount: PropTypes.number.isRequired,
  visitsCount: PropTypes.number.isRequired,
  fleetIds: PropTypes.array.isRequired,
  selectedFleetIds: PropTypes.array.isRequired,
  updateFilterState: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { jobReducer, filterReducer } = state;

  return {
    fleetIds: fleetIdsSelector(state),
    selectedFleetIds: filterReducer.fleetIds,
    activeFleetsCount: jobReducer.activeFleetsCount,
    fleetsCount: jobReducer.fleetsCount,
    visitsCount: jobReducer.visitsCount,
  };
}

export default connect(mapStateToProps, { updateFilterState })(FilterContainer);
