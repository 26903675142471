import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import locationsSelector from "../selectors/locationsSelector";
import MyMarker from "../components/MyMarker";
import { updateLocationState } from "../actions/map";
import { getNewLocationNearALocation } from "../helpers/location";

class LocationsContainer extends PureComponent {

  componentDidMount() {
    this.props.setCenter(this.props.locations.filter(l => !!l.lat)[0]);
  }

  render() {
    const { locations, currentLocation } = this.props;

    let new_locations = [];
    let duplicate_locations = [];
    let index = 0;

    locations.map( (location) => {
      let foundIndex = -1;

      if (new_locations.length) {
        foundIndex = new_locations.map( (element, i) => {
          if ( element.lat === location.lat && element.lng === location.lng ) {
            return i;
          }        
          return -1;
        });

        if ( foundIndex > -1 ) {
          new_locations[foundIndex].count = new_locations[foundIndex].count + 1;
          duplicate_locations[index] = { lat: location.lat, lng: location.lng };
          index++;
        }
      } 

      if (foundIndex === -1) {
        new_locations.push({ lat: location.lat, lng: location.lng, count: 1});
      }

      return true;
    });

    return locations.map((location, i) => {
      let isOnSameLocation = duplicate_locations.map( (element, index) => {
        if ( element.lat === location.lat && element.lng === location.lng ) {
          return index;
        }        
        return -1;
      });

      let new_location = Object.assign({}, location);
      if( isOnSameLocation > -1 ) {
        new_location = getNewLocationNearALocation(new_location);
      }

      return (
        !!location.lat &&
            <MyMarker key={i} location={new_location} currentLocation={currentLocation} updateLocationState={this.props.updateLocationState}/>
      );
    });
  }
}

LocationsContainer.propTypes = {
  currentLocation: PropTypes.object,
  locations: PropTypes.array.isRequired,
  setCenter: PropTypes.func.isRequired,
  updateLocationState: PropTypes.func.isRequired,
};

function mapStateToProps(state, { fleetId }) {
  const locations = locationsSelector(state)[fleetId] || [];
  return {
    locations,
  };
}

export default connect(mapStateToProps, { updateLocationState })(LocationsContainer);
