import * as actions from "../global/actionTypes";

const initState = {
  currentLocation: undefined,
};

export default function(state = initState, action = {}) {
  switch (action.type) {
  case actions.UPDATE_CURRENT_LOCATION_STATE:
    return {
      currentLocation: action.location,
    };
  default:
    return state;
  }
}
