import * as actions from "../global/actionTypes";
import _ from "lodash";

const initState = {
  jobId: undefined,
  isFetching: false,
  activeFleetsCount: 0,
  fleetsCount: 0,
  visitsCount: 0,
  data: {},
};

export default function(state = initState, action = {}) {
  switch (action.type) {
  case actions.FETCH_JOB_REQUEST:
    return {
      jobId: action.jobId,
      isFetching: true,
      activeFleetsCount: 0,
      fleetsCount: 0,
      visitsCount: 0,
      data: {},
    };
  case actions.FETCH_JOB_FAILURE:
    return {
      jobId: action.jobId,
      isFetching: false,
      activeFleetsCount: 0,
      fleetsCount: 0,
      visitsCount: 0,
      data: {},
    };
  case actions.FETCH_JOB_SUCCESS:
    const data = action.response.data || {};
    let activeFleetsCount = 0;

    if (!!data && !!data.output && !!data.output.solution) {
      const solution = data.output.solution;

      _.forEach(_.keys(solution), fleetId => {
        if (solution[fleetId].length > 0) {
          activeFleetsCount++;
        }
      });
    }

    return {
      jobId: action.jobId,
      isFetching: false,
      data: data,
      activeFleetsCount,
      fleetsCount: data.fleet || 0,
      visitsCount: data.visits || 0,
    };
  default:
    return state;
  }
}
