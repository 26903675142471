import { createSelector } from "reselect";

const csvDataSelector = createSelector(
  (state) => state.jobReducer.data,
  (state) => state.filterReducer.fleetIds,
  (data, fleetIds) => {
    const results = [
      ["Fleet", "#", "Type", "Arrival Time", "Finish Time", "Address", "Latitude", "Longitude"],
    ];

    if (
      !data.input ||
      !data.network ||
      !data.input.fleet ||
      !data.output.solution
    ) {
      return results;
    }

    const { network, output: { solution, unserved } } = data;

    let vSolution = [];
    Object.keys(solution).forEach((vehicleId) => {
      if (fleetIds.indexOf(vehicleId) > -1) {
        vSolution = solution[vehicleId];
        vSolution.forEach((vs) => {
          const vNetwork = network[vs.location_id] || network[`${vs.location_id}_${vs.type}`];
          if (vNetwork && vNetwork.lat && vNetwork.lng) {
            results.push([
              vehicleId,
              vs.location_id,
              vs.type,
              vs.arrival_time,
              vs.finish_time,
              vs.address || vs.location_name,
              vNetwork.lat,
              vNetwork.lng,
            ]);
          }
        });
      }
    });

    if (!!unserved) {
      Object.keys(unserved).forEach((visitId) => {
        const vNetwork = network[visitId] || network[`${visitId}_pickup`] || {};

        if (!!vNetwork) {
          results.push([
            "unserved",
            visitId,
            "",
            "",
            "",
            vNetwork.name,
            vNetwork.lat,
            vNetwork.lng,
          ]);
        }
      });
    }

    return results;
  }
);

export default csvDataSelector;
