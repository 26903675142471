import { take, put, call, fork } from "redux-saga/effects";
import * as actionType from "../global/actionTypes";
import * as action from "../actions/job";
import * as api from "../services/api";

/** *************************** Subroutines ************************************/
function* fetchJob(endpoint, jobId, simulationId, bookingTime) {
  const { response, error } = yield call(api.fetchJob, endpoint, jobId, simulationId, bookingTime);

  if (response) {
    yield put(action.fetchJob.success(jobId, response));
  } else {
    yield put(action.fetchJob.failure(jobId, error));
  }
}

/** ****************************************************************************/
/** ***************************** WATCHERS *************************************/
/** ****************************************************************************/
export default function* watchJob() {
  while (true) {
    const { endpoint, jobId, simulationId, bookingTime } = yield take(actionType.FETCH_JOB_REQUEST);

    yield fork(fetchJob, endpoint, jobId, simulationId, bookingTime);
  }
}
