import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Marker } from "react-google-maps";

class MyMarker extends PureComponent {
  state = {
    isOpen: false,
  }

  getLabel() {
    const { location } = this.props;
    if (location.isStartingLocation || location.isLastLocation) {
      return undefined;
    }
    return {
      text: `${location.number}`,
      color: `#fff`,
      fontFamily: "Lato",
      fontWeight: "bold",
      fontSize: '11px',
    };
  }

  getIcon() {
    const { location } = this.props;

    if (location.isStartingLocation) {
      return {
        url: `/markers/vehicle/${location.color || "C4C4C4"}.png`,
        scaledSize: { width: 32, height: 32 },
      };
    }
    if (location.isLastLocation) {
      return {
        url: `/markers/end/${location.color || "C4C4C4"}.png`,
        scaledSize: { width: 32, height: 36 },
      };
    }
    return {
      url: `/markers/${location.vType}/${location.color || "C4C4C4"}.png`,
      origin: { x: 0, y: -7 },
      scaledSize: { width: 32, height: 36 },
    };
  }

  render() {
    const { location, currentLocation } = this.props;
    const icon = this.getIcon();
    const label = this.getLabel();
    const position = {lat: location.lat, lng: location.lng};

    return (
      <Marker
        position={position}
        label={label}
        zIndex={location === currentLocation ? 999999 : location.zIndex}
        icon={icon}
        onClick={this.openInfoWindow}
      />
    );
  }

  openInfoWindow = () => {
    this.props.updateLocationState(this.props.location);
  }
}

MyMarker.propTypes = {
  location: PropTypes.object.isRequired,
  currentLocation: PropTypes.object,
  updateLocationState: PropTypes.func.isRequired,
};

export default MyMarker;
