import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import MyGoogleMap from "../components/MyGoogleMap";
import locationsSelector from "../selectors/locationsSelector";

class MapContainer extends Component {
  render() {
    const { locations, currentLocation, fleetIds } = this.props;
    return (
      <MyGoogleMap
        fleetIds={fleetIds}
        currentLocation={currentLocation}
        locations={locations}
      />
    );
  }
}

MapContainer.propTypes = {
  fleetIds: PropTypes.array.isRequired,
  currentLocation: PropTypes.object,
  locations: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    currentLocation: state.mapReducer.currentLocation,
    fleetIds: state.filterReducer.fleetIds,
    locations: locationsSelector(state) || {},
  };
}

export default connect(mapStateToProps)(MapContainer);
