import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { GoogleMap, withScriptjs, withGoogleMap, InfoWindow } from "react-google-maps";
import { compose, withProps } from "recompose";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimesCircle as farTimesCircle } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";

import ConfigApp from "../config/application";
import LocationsContainer from "../containers/LocationsContainer";
import DirectionsContainer from "../containers/DirectionsContainer";
import hexToRGB from "../helpers/hexToRGB";
import { formatDate } from "../helpers/dateFormatter";

library.add(farTimesCircle);

const Table = styled.table`
  border: none;
  border-collapse: collapse;
  font-family: Lato;
  font-size: 12px;
  color: #B8C4C9;
  font-weight: 400;
  tbody {
    tr > td {
      padding: 4px 8px;
    }
    tr > td:last-child {
      color: #56666C;
      min-width: 200px;
    }
    tr:nth-child(even) {
      border-radius: 2px;
      background-color: #ECEFF1;
    }
  }
`;

const TopTable = styled.table`
  margin: 10px 0;
  border: none;
  border-collapse: collapse;
  font-family: Lato;
  font-size: 12px;
  color: #B8C4C9;
  font-weight: 400;
  tbody {
    tr > td, tr > th {
      font-weight: normal;
      text-align: left;
      padding: 3px 15px 3px 0;
    }
    tr > td {
      color: #56666C;
    }
  }
`;

const BottomTable = styled.table`
  border-collapse: collapse;
  margin-top: 5px;
  font-family: Lato;
  font-size: 12px;
  color: #5C5B5A;
  font-weight: 400;
  thead {
    tr {
      background-color: #56666C;
    }
    tr > th {
      text-align: left;
      font-family: Lato;
      font-weight: 700;
      color: white;
      padding: 10px;
      white-space: nowrap;
    }
  }
  tbody {
    tr > td {
      padding: 10px;
      white-space: nowrap;
    }
    tr > td > a {
      color: #599CF5;
      text-decoration: none;
    }
    tr:nth-child(even) {
      background-color: #F1F5F7;
    }
  }
`;

const IconContainer = styled.div`
  box-shadow: 0 1px 3px 0 rgba(86,102,108,0.50);
  border-radius: 50%;
`;

const IconContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 21px;
  font-size: 7px;
  background-color: ${({color}) => hexToRGB(color, 0.8) || hexToRGB("gray", 0.8)};
  border: 1px solid ${({color}) => color || "gray"};
  text-align: center;
  border-radius: 50%;
  color: white;
`;

const InlineBlock = styled.div`
  display: flex;
  align-items: center;

  > div {
    margin-right: 10px;
  }
`;

const Badge = styled.span`
  padding: 0.286em 0.5em;
  border: 0.071em solid ${({color}) => color || "#00B8D4"};
  border-radius: 0.143em;
  font-size: 0.643em;
  font-family: Lato;
  font-weight: 700;
  cursor: pointer;
  color: ${({color}) => color || "#00B8D4"};
  background-color: ${({color}) => hexToRGB(color, 0.2) || hexToRGB("#00B8D4", 0.2)};
`;

const defaultCenter = {
  lat: 54.0590515136719, lng: -2.83961701393127,
};

function initMap() {
  console.log("Map loaded!");
}
window.initMap = initMap;

const MyMap = compose(
  withProps({
    googleMapURL: ConfigApp.googleMapURL,
    loadingElement: <div style={{ height: "100%" }} />,
    containerElement: <div style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0}} />,
    mapElement: <div style={{ height: "100%" }} />,
  }),
  withScriptjs,
  withGoogleMap
)(({center, setCenter, fleetIds, currentLocation, renderInfoWindow}) => {
  return (
    <GoogleMap
      defaultZoom={12}
      defaultCenter={defaultCenter}
      defaultOptions={{
        styles: [{"featureType": "administrative", "elementType": "labels", "stylers": [{"visibility": "off"}]}, {"featureType": "administrative.country", "elementType": "all", "stylers": [{"visibility": "on"}]}, {"featureType": "administrative.province", "elementType": "all", "stylers": [{"visibility": "on"}]}, {"featureType": "administrative.locality", "elementType": "all", "stylers": [{"visibility": "on"}]}, {"featureType": "administrative.neighborhood", "elementType": "all", "stylers": [{"visibility": "off"}]}, {"featureType": "administrative.land_parcel", "elementType": "all", "stylers": [{"visibility": "off"}]}, {"featureType": "landscape.natural", "elementType": "geometry.fill", "stylers": [{"color": "#c4eeb5"}]}, {"featureType": "landscape.natural.terrain", "elementType": "geometry.fill", "stylers": [{"color": "#bde6af"}]}, {"featureType": "poi", "elementType": "all", "stylers": [{"visibility": "off"}]}, {"featureType": "poi", "elementType": "labels", "stylers": [{"visibility": "off"}]}, {"featureType": "poi.attraction", "stylers": [{"visibility": "off"}]}, {"featureType": "poi.business", "stylers": [{"visibility": "off"}]}, {"featureType": "poi.government", "stylers": [{"visibility": "off"}]}, {"featureType": "poi.medical", "stylers": [{"visibility": "off"}]}, {"featureType": "poi.park", "stylers": [{"visibility": "off"}]}, {"featureType": "poi.place_of_worship", "stylers": [{"visibility": "off"}]}, {"featureType": "poi.school", "stylers": [{"visibility": "off"}]}, {"featureType": "poi.sports_complex", "stylers": [{"visibility": "off"}]}, {"featureType": "transit", "elementType": "all", "stylers": [{"visibility": "off"}]}, {"featureType": "transit.station", "elementType": "all", "stylers": [{"visibility": "off"}]}],
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
      }}
      center={center}
      disableDefaultUI
    >
      {fleetIds.map((fleetId) =>
        <DirectionsContainer key={fleetId} fleetId={fleetId}/>
      )}
      {fleetIds.map((fleetId) =>
        <LocationsContainer
          key={fleetId}
          fleetId={fleetId}
          currentLocation={currentLocation ? currentLocation : undefined}
          setCenter={setCenter}
        />
      )}
      {currentLocation && renderInfoWindow(currentLocation) }
    </GoogleMap>
  );
});

class MyGoogleMap extends PureComponent {
  state = {
    center: defaultCenter,
  }

  componentWillReceiveProps(nextProps) {
    const { currentLocation } = this.props;

    if (nextProps.currentLocation && nextProps.currentLocation !== currentLocation) {
      this.setState({
        center: {
          lat: nextProps.currentLocation.lat,
          lng: nextProps.currentLocation.lng,
        },
      });
    }
  }

  getDepotIcon(location) {
    return (
      <IconContainer>
        <IconContent color={`#${location.color}`}>
          { location.vehicleId !== "unserved" &&
            <FontAwesomeIcon icon={"truck"} flip="horizontal"/>
          }
          { location.vehicleId === "unserved" &&
            <FontAwesomeIcon icon={"times-circle"} style={{ fontSize: 12 }}/>
          }
        </IconContent>
      </IconContainer>
    );
  }

  getLoad(load) {
    if (typeof load === "number") {
      return load.toString();
    }

    if (typeof load === "object") {
      const res = [];

      _.forEach(_.keys(load), (k) => {
        res.push(`${k.replace("load_","")}: ${load[k]}`);
      });

      return res.join(", ");
    }

    return "";
  }

  renderInfoWindow = (location) => {
    if (this.isVehicle(location)) {
      const PICKUP_TYPE = "pickup";
      const FILL_LOAD = "fill";
      const locations = this.props.locations[location.vehicleId] || [];
      const origin = locations[0] || {};
      const destination = locations.slice(-1)[0] || {};

      const totalLoad = {};
      const fillLoad = {};

      if(!!origin && !!origin.shift_start){
        const isDigit = /^\d+$/.test(origin.shift_start);
        if(isDigit) {
          origin.shift_start = formatDate(origin.shift_start);
        }
      }
      if(!!origin && !!origin.shift_end){
        const isDigit = /^\d+$/.test(origin.shift_end);
        if(isDigit) {
          origin.shift_end = formatDate(origin.shift_end);
        }
      }

      locations.map(({load,vType}) => {
        if (Array.isArray(load)) {
          if(vType === PICKUP_TYPE){
            for(let index=0; index < load.length; index++){
              if(!totalLoad[index]){
                totalLoad[index] = 0;
                fillLoad[index]="";
              }
              if(load[index] === FILL_LOAD){
                fillLoad[index]= FILL_LOAD;
                totalLoad[index] = location.capacity[index];
              } else if(fillLoad[index] !== FILL_LOAD){
                totalLoad[index] = totalLoad[index] + parseInt(load[index]);
              }
            }
          }
        }        
      });
      // End Locations Load
      locations.map((locationSelected) => {

        if(!!locationSelected.start){
          if(/^\d+$/.test(locationSelected.start)){
            locationSelected.start = formatDate(locationSelected.start);
          }
        }

        if(!!locationSelected.end){
          if(/^\d+$/.test(locationSelected.end)){
            locationSelected.end = formatDate(locationSelected.end);
          }
        }
      });

      return (
        <InfoWindow
          key={location.location_id}
          defaultOptions={{
            pixelOffset: {
              height: location.isStartingLocation || location.isLastLocation ? -65 : -80,
              width: 0,
            },
          }}
          position={location}
        >
          <div>
            <InlineBlock>
              { this.getDepotIcon(location) }
              <Badge color={`#${location.color}`}>{location.vehicleId}</Badge>
            </InlineBlock>
            <TopTable>
              <tbody>
                <tr><th>Start Location</th><td colSpan="7">{origin.location_name || origin.address}</td></tr>
                <tr><th>End Location</th><td colSpan="7">{destination.location_name || destination.address}</td></tr>
                <tr><th>Shift Start</th><td>{origin.shift_start}</td><th>Shift End</th><td>{origin.shift_end}</td><th>Capacity</th><td>{this.getLoad(location.capacity)}</td><th>Total Load</th><td>{totalLoad.all || this.getLoad(totalLoad)}</td></tr>
              </tbody>
            </TopTable>
            <BottomTable>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Type</th>
                  <th>Mode</th>
                  <th width="60">Arrival</th>
                  <th width="60">Finish</th>
                  <th width="60">Duration</th>
                  <th width="90">Time window</th>
                  <th>Address</th>
                  <th>Load</th>
                </tr>
              </thead>
              <tbody>
                {locations.map((l, i) =>
                  <tr key={i}>
                    <td>{l.location_id}</td>
                    <td>{l.type}</td>
                    <td>{l.mode}</td>
                    <td>{l.arrival_time}</td>
                    <td>{l.finish_time}</td>
                    <td>{l.duration}</td>
                    <td style={{textAlign: "center"}}>{[l.start, l.end].join(" - ")}</td>
                    <td><a href={`${ConfigApp.publicGoogleMapURL}/${l.lat},${l.lng}`} target="_blank" rel="noreferrer">{l.address || l.location_name || "#"}</a></td>
                    <td>{this.getLoad(l.load)}</td>
                  </tr>
                )}
              </tbody>
            </BottomTable>
          </div>
        </InfoWindow>
      );
    }
    let timeWindowStart =  location.start;
    let timeWindowEnd = location.end;
    
    if(!!location.start && !isNaN(location.start)){
      if(/^\d+$/.test(location.start)) {
        timeWindowStart = formatDate(location.start);
      }
      if(/^\d+$/.test(location.end)) {
        timeWindowEnd = formatDate(location.end);
      }
    }

    return (
      <InfoWindow
        key={location.location_id}
        defaultOptions={{
          pixelOffset: {
            height: location.isStartingLocation || location.isLastLocation ? -65 : -80,
            width: 0,
          },
        }}
        position={location}
      >
        <div>
          <InlineBlock style={{ paddingBottom: 15 }}>
            { this.getDepotIcon(location) }
            <Badge color={`#${location.color}`}>{location.vehicleId}</Badge>
          </InlineBlock>
          <Table>
            <tbody>
              <tr><td>Location ID:</td><td>{location.location_id}</td></tr>
              <tr><td>Location Name:</td><td>{location.location_name}</td></tr>
              <tr><td>Time Window:</td><td>{timeWindowStart} - {timeWindowEnd}</td></tr>
              <tr><td>Arrival Time:</td><td>{location.arrival_time}</td></tr>
              <tr><td>Finish Time:</td><td>{location.finish_time}</td></tr>
              <tr><td>Type:</td><td>{location.type}</td></tr>
              <tr><td>Mode:</td><td>{location.mode}</td></tr>
              <tr><td>Priority:</td><td>{location.priority}</td></tr>
              <tr><td>Load:</td><td>{this.getLoad(location.load)}</td></tr>
              <tr><td>Address:</td><td>{location.address}</td></tr>
              {location.unservedReason && <tr><td>Unserved Reason:</td><td style={{color: "red"}}>{location.unservedReason}</td></tr>}
            </tbody>
          </Table>
        </div>
      </InfoWindow>
    );
  }

  render() {
    return (
      <MyMap
        fleetIds={this.props.fleetIds}
        center={this.state.center}
        setCenter={this.setCenter}
        currentLocation={this.props.currentLocation}
        renderInfoWindow={this.renderInfoWindow}
      />
    );
  }

  setCenter = (position) => {
    if (position && !!position.lat) {
      this.setState({center: position || defaultCenter});
    }
  }

  isVehicle = (location) => {
    return location.isStartingLocation;
  }
}

MyGoogleMap.propTypes = {
  fleetIds: PropTypes.array.isRequired,
  currentLocation: PropTypes.object,
  locations: PropTypes.object.isRequired,
};

export default MyGoogleMap;
