import React from "react";
import { withFormik } from "formik";
import styled from "styled-components";
import PropTypes from "prop-types";
import Label from "./Label";
import Button from "./Button";
import TextInput from "./TextInput";
import Panel, { PanelHeader, PanelBody } from "./Panel";

const InlineBlock = styled.div`
  padding-bottom: 10px;
  input {
    display: inline-block;
    width: 70%;
    height: 2.553em;
    color: #56666C;
  }
  div.gap {
    display: inline-block;
    width: 5%;
  }
  button {
    width: 25%;
    height: 2.553em;
  }
`;

const form = withFormik({
  mapPropsToValues: (props) => {
    return {
      jobId: props.jobId || "",
    };
  },
  handleSubmit: (values, { props: { fetchJobRequest, endpoint } }) => {    
    window.history.pushState(null, "", `/jobs/${values.jobId}?endpoint=${endpoint}`);
    fetchJobRequest(endpoint, values.jobId,"","");
  },
});

const RouteJobForm = ({ handleChange, handleSubmit, values, isFetching }) => (
  <div>
    <Panel>
      <PanelHeader>Route Solution</PanelHeader>
      <PanelBody>
        <form onSubmit={handleSubmit}>
          <Label>Job ID</Label>
          <InlineBlock>
            <TextInput
              type="text"
              name="jobId"
              placeholder="Enter Job ID."
              onChange={handleChange}
              value={values.jobId}
            />
            <div className="gap"/>
            <Button
              title={isFetching ? "Loading" : "Submit"}
              type="submit"
              disabled={isFetching}
            />
          </InlineBlock>
        </form>
      </PanelBody>
    </Panel>
  </div>
);

RouteJobForm.propTypes = {
  fetchJobRequest: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  values: PropTypes.object,
};

export default form(RouteJobForm);
