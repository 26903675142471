import React from "react";
import styled, { css } from "styled-components";

const StyledButton = styled.a`
  display: inline-block;
  box-sizing: border-box;
  border-radius: 0.143em;
  border: 0.071em solid #F6BE41;
  background-color: transparent;
  font-size: 0.857em;
  font-family: Lato;
  font-weight: 700;
  color: #F6BE41;
  padding: 0.371em 0.571em;
  height: 2.143em;
  text-decoration: none;
  text-align: center;
  ${({disabled}) => disabled && css`
    color: #B8C4C9;
    border: 0.071em solid #B8C4C9;
    background-color: #ECEFF1;
  `}
`;

const ExportCSV = ({title, ...props}) => (
  <StyledButton {...props} >{title}</StyledButton>
);

export default ExportCSV;
