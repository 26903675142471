import styled from "styled-components";

export const PanelHeader = styled.div`
  background-color: #56666C;
  padding: 1em;
  border-top-left-radius: .286em;
  border-top-right-radius: .286em;
  color: white;
  font-family: Lato;
  font-weight: 700;
`;

export const PanelBody = styled.div`
  padding: ${({padding}) => padding || ".6em 1em"};
  background-color: white;
  border-bottom-left-radius: .286em;
  border-bottom-right-radius: .286em;
  max-height: 28.571em;
  overflow: scroll;
`;

export const Panel = styled.div`
  background-color: white;
  border-radius: .286em;
  box-shadow: 0 1px 5px 0 rgba(86,102,108,0.24);
  overflow: hidden;
  margin: ${({margin}) => margin || "0"};
  hr {
    margin: 0;
    border-width: 0.061em;
    border-color: #B8C4C9;
  }
`;

export default Panel;
