import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import SearchContainer from "../containers/SearchContainer";
import FilterContainer from "../containers/FilterContainer";
import JobContainer from "../containers/JobContainer";
import MapContainer from "../containers/MapContainer";
import qs from "qs";
import ConfigApp from "../config/application";

const LeftView = styled.div`
  position: absolute;
  width: 21.429em;
  left: 1.429em;
  top: 2.143em;
  z-index: 50;
`;

const SearchView = styled.div`
  position: absolute;
  width: 270px;
  right: 1.429em;
  top: 2.143em;
  z-index: 50;
`;


const App = ({ match, location }) => {
  const endpoint = qs.parse(location.search, { ignoreQueryPrefix: true }).endpoint || `${ConfigApp.apiBackendURI}`;
  
  return (
    <div>
      <LeftView>
        <JobContainer jobId={match.params.id} endpoint={endpoint} simulationId={match.params.id} bookingTime={match.params.bookingTime} />
        <FilterContainer />
      </LeftView>
      <SearchView>
        <SearchContainer />
      </SearchView>
      <MapContainer />
    </div>
  );
};

App.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default App;
