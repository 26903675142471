import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faMapPin as fasMapPin } from "@fortawesome/free-solid-svg-icons";
import Panel, { PanelBody }  from "./Panel";
import Label from "./Label";
import RadioButton from "./RadioButton";
import FleetContainer from "../containers/FleetContainer";
import ExportCsvContainer from "../containers/ExportCsvContainer";

library.add(fasMapPin);

const InlineBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NoData = styled.p`
  text-align: center;
  color: #B8C4C9;
  font-size: 1.143em;
  font-family: Lato;
  font-weight: 300;
`;

const FleetView = styled.div`
  position: relative;
  margin-top: 10px;
`;

const Counter = styled.p`
  font-size: 12px;
  margin: 0 0 5px 10px;
  color: #56666C;
`;

const CheckBox = styled.input`
  position: absolute;
  top: 0.571em;
`;

class Filter extends PureComponent {
  state = {
    isAll: true,
    selectedFleets: [],
    activeFleetsCount: 0,
    fleetsCount: 0,
    visitsCount: 0,
    locations: [],
    isOpen: false,
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.fleetIds !== this.props.fleetIds || nextProps.visitsCount !== this.props.visitsCount) {
      this.setState({
        isAll: true,
        selectedFleets: nextProps.fleetIds,
        activeFleetsCount: nextProps.activeFleetsCount,
        fleetsCount: nextProps.fleetsCount,
        visitsCount: nextProps.visitsCount,
        isOpen: false,
        locations: [],
      });
    }
    if (nextProps.selectedFleetIds !== this.props.selectedFleetIds) {
      this.setState({selectedFleets: nextProps.selectedFleetIds});
    }
  }

  onCheckBoxCheck = (fleetId) => {
    return function onChange(e) {
      let selectedFleets = [];
      if (e.target.checked) {
        selectedFleets = [...this.state.selectedFleets, fleetId];
      } else {
        selectedFleets = this.state.selectedFleets.filter((id) => id !== fleetId);
      }
      this.setState({selectedFleets}, () => {
        this.props.updateFilterState({fleetIds: selectedFleets});
      });
    }.bind(this);
  }

  onAllChange = (e) => {
    const { fleetIds } = this.props;
    const isAll = e.target.value === "1";
    const selectedFleets = isAll ? fleetIds : [];
    this.setState({isAll, selectedFleets}, () => {
      this.props.updateFilterState({fleetIds: selectedFleets});
    });
  }

  render() {
    const { isAll, selectedFleets, activeFleetsCount, fleetsCount, visitsCount } = this.state;
    const { fleetIds } = this.props;
    let totalVehicles = activeFleetsCount;

    if (activeFleetsCount !== fleetsCount) {
      totalVehicles = `${activeFleetsCount}/${fleetsCount}`;
    }

    return (
      <Panel margin="1.429em 0 0 0">
        <PanelBody style={{
          borderBottom: "1px solid #ECEFF1",
          borderRadius: 0,
          paddingTop: "12px",
          paddingBottom: "12px",
        }}>
          <InlineBlock style={{ marginBottom: 2 }}>
            <Label style={{ fontSize: "14px" }}>Fleet</Label>
            <InlineBlock>
              <Counter><FontAwesomeIcon icon={"truck"} flip="horizontal"/> Drivers: {totalVehicles}</Counter>
              <Counter><FontAwesomeIcon icon={"map-pin"}/> Stops: {visitsCount}</Counter>
            </InlineBlock>
          </InlineBlock>
          <InlineBlock>
            <RadioButton
              name="all"
              value={1}
              label="Select All"
              checked={isAll}
              onChange={this.onAllChange}
            />
            <RadioButton
              name="all"
              value={0}
              label="Unselect All"
              checked={!isAll}
              onChange={this.onAllChange}
            />
            <ExportCsvContainer disabled={!fleetIds[0]} />
          </InlineBlock>
        </PanelBody>
        {!fleetIds[0] &&
          <PanelBody>
            <NoData>No Data</NoData>
          </PanelBody>
        }
        {fleetIds[0] &&
          <PanelBody padding="0 1em .6em">
            {fleetIds.map((fleetId) =>
              <FleetView key={fleetId}>
                <CheckBox
                  type="checkbox"
                  checked={selectedFleets.indexOf(fleetId) > - 1}
                  onChange={this.onCheckBoxCheck(fleetId)}
                />
                <FleetContainer fleetId={fleetId}/>
              </FleetView>
            )}
          </PanelBody>
        }
      </Panel>
    );
  }
}

Filter.propTypes = {
  fleetIds: PropTypes.array.isRequired,
  activeFleetsCount: PropTypes.number.isRequired,
  fleetCount: PropTypes.number,
  visitsCount: PropTypes.number.isRequired,
  selectedFleetIds: PropTypes.array.isRequired,
  updateFilterState: PropTypes.func.isRequired,
};


export default Filter;
