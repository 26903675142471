import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { fetchJobRequest } from "../actions/job";
import JobForm from "../components/JobForm";
import SimulationForm from "../components/SimulationForm";

class JobContainer extends PureComponent {
  componentDidMount = () => {
    const { endpoint, jobId, simulationId, bookingTime } = this.props;

    if (!!jobId) {
      this.props.fetchJobRequest(endpoint, jobId, simulationId, bookingTime);
    }
  }

  render() {
    const { isFetching, jobId, endpoint, simulationId, bookingTime } = this.props;
    if(!simulationId || simulationId.length == 0){
      return <JobForm fetchJobRequest={this.props.fetchJobRequest} isFetching={isFetching} jobId={jobId} endpoint={endpoint} simulationId={simulationId} bookingTime={bookingTime} />;
    }
    return <SimulationForm fetchJobRequest={this.props.fetchJobRequest} isFetching={isFetching} simulationId={simulationId} bookingTime={bookingTime} endpoint={endpoint}/>
  }
}

JobContainer.propTypes = {
  jobId: PropTypes.string,
  endpoint: PropTypes.string,
  simulationId: PropTypes.string,
  bookingTime: PropTypes.string,
  isFetching: PropTypes.bool,
  fetchJobRequest: PropTypes.func.isRequired,
};

function mapStateToProps({ jobReducer: { isFetching } }) {
  return { isFetching };
}

export default connect(mapStateToProps, { fetchJobRequest })(JobContainer);
