import { createSelector } from "reselect";
import _ from "lodash";
import Colors from "../config/colors";

const getType = (type) => {
  let res;

  if (!type) {
    res = [];
  } else {
    if (typeof type === "string") {
      res = [type];
    } else {
      res = type;
    }
  }

  return res.join(", ");
};

const locationsSelector = createSelector(
  (state) => state.jobReducer.data,
  (data) => {
    if (
      !data.input ||
      !data.output ||
      !data.output.solution
    ) {
      return {};
    }

    const { type, network, input: { visits, fleet }, output: { solution } } = data;
    let { output: { unserved } } = data;

    const results = {};
    const totalColors = Colors.random.length;
    let vSolution = [];

    Object.keys(solution).forEach((vehicleId, vIndex) => {
      results[vehicleId] = [];
      if(!fleet[vehicleId]) return;
      
      vSolution = solution[vehicleId];
      const vehicle = {
        ...fleet[vehicleId],
        type: getType(fleet[vehicleId].type),
      };

      if (vSolution.length === 0) {
        const startLocation = vehicle.start_location || {};
        vSolution.push({
          "location_id": startLocation.id,
          "location_name": startLocation.name,
        });
      }

      vSolution.forEach((vs, number) => {
        const isVehicle = number === 0;
        const locTag = vs.type === "break" ? "lunch_0" : vs.type;
        vs.load = vs.type === "break" ? [0] : vs.load;

        const vNetwork = network[vs.location_id] || network[`${vs.location_id}_${locTag}`];
        const vVisit = {
          ...(visits[vs.location_id] || {}),
          ...(visits[vs.location_id] ? visits[vs.location_id][vs.type] : {}),
        };

        if (vNetwork && vNetwork.lat && vNetwork.lng) {
          results[vehicleId].push({
            ...(isVehicle ? vehicle : {
              ...vVisit,
            }),
            ...vs,
            ...{
              ...vNetwork,
              lat: parseFloat(vNetwork.lat), lng: parseFloat(vNetwork.lng),
            },
            vType: vs.type || "stop",
            number,
            vehicleId,
            location_name: vs.location_name || vs.location_id,
            zIndex: (999999 - (30 * number + vIndex)),
            color: Colors.random[vIndex % totalColors],
            isLastLocation: !vSolution[number + 1],
            isStartingLocation: isVehicle,
            isVisit: vSolution[0].location_id !== vs.location_id,
            type: getType(isVehicle ? vehicle.type : vVisit.type),
            mode: isVehicle ? "" : vs.type,
          });
        }
      });
    });

    results.unserved = [{
      location_id: "",
      location_name: "",
      vehicleId: "unserved",
      number: 0,
      zIndex: 1,
      color: "C4C4C4",
      isLastLocation: false,
      isStartingLocation: true,
      isVisit: false,
    }];

    if (!!unserved) {
      if (_.isArray(unserved)) {
        const unservedTmp = {};
        unserved.forEach((visitId) => {
          unservedTmp[visitId] = "Sorry, none of the stops can be scheduled. Please check your inputs and constraints.";
        });
        unserved = unservedTmp;
      }
      let number = 0;
      
      _.keys(unserved).forEach((visitId) => {
        const stops = [];
        const visit = visits[visitId];

        if(!visit) return;
        
        if (type === "VRP") {
          stops.push({...visit, vType: "stop"});
        } else {
          stops.push({...visit, ...visit['pickup'], vType: "pickup"});
          stops.push({...visit, ...visit['dropoff'], vType: "dropoff"});
        }
        _.each(stops, s => {
          const vNetwork = network[visitId] || network[`${visitId}_${s.vType}`] || {};
          number++;

          results.unserved.push({
            ...vNetwork,
            ...s,
            type: s.vType,
            location_id: visitId,
            location_name: vNetwork.name || visitId,
            vehicleId: "unserved",
            number: number,
            zIndex: number + 1,
            color: "C4C4C4",
            isLastLocation: false,
            isStartingLocation: false,
            isVisit: true,
            unservedReason: unserved[visitId],
          });
        });
      });
    }
    console.log(results);
    return results;
  }
);

export default locationsSelector;
