import { library } from "@fortawesome/fontawesome-svg-core";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { faTruck, faFlag } from "@fortawesome/free-solid-svg-icons";

export default function() {
  library.add(
    faTruck,
    faFlag,
    faCircle
  );
}
