import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Fleet from "../components/Fleet";
import locationsSelector from "../selectors/locationsSelector";
import { updateLocationState } from "../actions/map";

class FleetContainer extends PureComponent {
  render() {
    // console.log("FleetContainer");
    const { locations, currentLocation } = this.props;
    return (
      <Fleet
        locations={locations}
        updateLocationState={this.props.updateLocationState}
        currentLocation={currentLocation}
      />
    );
  }
}

FleetContainer.propTypes = {
  locations: PropTypes.array.isRequired,
  fleetId: PropTypes.string.isRequired,
  updateLocationState: PropTypes.func.isRequired,
  currentLocation: PropTypes.object,
};

function mapStateToProps(state, { fleetId }) {
  const locations = locationsSelector(state)[fleetId] || [];
  return {
    currentLocation: state.mapReducer.currentLocation,
    locations,
  };
}

export default connect(mapStateToProps, { updateLocationState })(FleetContainer);
