import * as actions from "../global/actionTypes";

function action(type, payload = {}) {
  return { type, ...payload };
}

export const fetchJob = {
  request: (endpoint, jobId, simulationId, bookingTime) => action(actions.FETCH_JOB_REQUEST, { endpoint, jobId, simulationId, bookingTime }),
  success: (jobId, response) =>
    action(actions.FETCH_JOB_SUCCESS, { jobId, response }),
  failure: (jobId, error) =>
    action(actions.FETCH_JOB_FAILURE, { jobId, error }),
};

export const fetchJobRequest = (endpoint, jobId, simulationId, bookingTime) => fetchJob.request(endpoint, jobId, simulationId, bookingTime);
