import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware, { END } from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "../reducers";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
const isProd = process.env.NODE_ENV === "production";

const configureStore = initialState => {
  const store = createStore(
    rootReducer,
    initialState,
    isProd ? applyMiddleware(...middlewares) : composeWithDevTools(applyMiddleware(...middlewares))
  );

  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);

  return store;
};

export default configureStore;
