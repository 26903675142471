import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Polyline } from "react-google-maps";
import locationsSelector from "../selectors/locationsSelector";

class DirectionsContainer extends PureComponent {
  render() {
    const { coords, locations } = this.props;

    return (
      <Polyline
        path={coords}
        defaultOptions={{
          geodesic: true,
          strokeColor: locations.length > 0 ? `#${locations[0].color}` : "#DEDEDE",
          strokeWeight: 3,
        }}
      />
    );
  }
}

DirectionsContainer.propTypes = {
  locations: PropTypes.array.isRequired,
  coords: PropTypes.array.isRequired,
  fleetId: PropTypes.string.isRequired,
  jobId: PropTypes.string.isRequired,
};

function mapStateToProps(state, { fleetId }) {
  const jobId = state.jobReducer.jobId;
  const locations = locationsSelector(state)[fleetId] || [];
  const coords = state.directionReducer.data[`${jobId}-${fleetId}`] || [];
  return {
    jobId,
    locations,
    coords,
  };
}

export default connect(mapStateToProps)(DirectionsContainer);
