import React from "react";
import styled from "styled-components";

const StyledButton = styled.div`
  input, label {
    cursor: pointer;
  }
  input:checked+label {
    color: #599CF5;
  }
`;
const Label = styled.label`
  font-family: Lato;
  font-weight: 700;
  font-size: 0.857em;
  color: #B8C4C9;
  margin-left: 0.286em;
`;

const RadioButton = ({label, ...props}) => (
  <StyledButton className="radio-button">
    <input {...props} id={label.replace(/\s/g, "_").toLowerCase()} type="radio"/>
    <Label htmlFor={label.replace(/\s/g, "_").toLowerCase()} >{label}</Label>
  </StyledButton>
);

export default RadioButton;
