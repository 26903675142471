const callApiEndpoint = (endpoint, opts) => {
  const options = {
    headers: Object.assign(
      {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      opts.headers
    ),
    method: opts.method,
    body: opts.body,
  };

  return fetch(endpoint, options)
    .then(response => response.json().then(json => ({ json, response })))
    .then(({ json, response }) => {
      if (!response.ok || response.status < 200 || response.status >= 300) {
        return Promise.reject(json);
      }

      return Object.assign({}, { data: json }, {
        message: json.message,
      });
    })
    .then(
      response => ({ response }),
      error => ({
        error: {
          message: error.error || "Something bad happened",
        },
      })
    );
};
// demo job id: jl33fmo4753
export const fetchJob = (endpoint, jobId, simulationId, bookingTime) => {

  if(bookingTime && bookingTime.length > 0){
    return callApiEndpoint(`${endpoint}/simulations/result/${simulationId}/${bookingTime}`, {
      method: "GET",
    });
  } else {
    return callApiEndpoint(`${endpoint}/jobs/${jobId}`, {
      method: "GET",
    });
  }
};
