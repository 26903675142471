const colors = {
  random: [
    "00C8B4",
    "23A9F6",
    "641EFD",
    "669A3D",
    "748BFF",
    "83C3FF",
    "9E69E0",
    "9FDC70",
    "C64EFF",
    "DC70C4",
    "F58585",
    "FF3535",
    "FF9365",
    "FFAB00",
  ],
};

export default colors;
