import styled from "styled-components";

const Label = styled.label`
  display:inline-block;
  max-width: 100%;
  font-size: 0.857em;
  font-family: Lato;
  font-weight: 700;
  color: #56666C;
  margin-bottom: 0.429em;
`;

export default Label;
