import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import locationsSelector from "../selectors/locationsSelector";
import Search from "../components/Search";
import { updateLocationState } from "../actions/map";

class SearchContainer extends Component {
  render() {
    const { locations } = this.props;

    return (
      <Search
        locations={locations}
        updateLocationState={this.props.updateLocationState}
      />
    );
  }
}

SearchContainer.propTypes = {
  locations: PropTypes.object.isRequired,
  updateLocationState: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const locations = locationsSelector(state) || {};
  return {
    locations,
  };
}

export default connect(mapStateToProps, { updateLocationState })(SearchContainer);
