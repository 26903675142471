import React, { useState } from "react";
import { withFormik } from "formik";
import styled from "styled-components";
import PropTypes from "prop-types";
import Label from "./Label";
import Panel, { PanelHeader, PanelBody } from "./Panel";

const InlineBlock = styled.div`
  padding-bottom: 10px;
  padding-left: 15px;
  div.gap {
    display: inline-block;
    width: 5%;
  }
  select {
    width: 100%
  }
`;

const form = withFormik({
  mapPropsToValues: (props) => {
    return {
      jobId: props.jobId || "",
    };
  }
});

const SimulationForm = ({ fetchJobRequest, isFetching, simulationId, bookingTime, endpoint }) => {
    const [currentBookingTime, setcurrentBookingTime] = useState(bookingTime);
    const [bookingTimesList, setBookingTimesList] = useState([bookingTime]);
    const changeBookingTime = ((newBookingTime) => {
        setcurrentBookingTime(newBookingTime);
        fetchJobRequest(endpoint, "",simulationId,newBookingTime);
    });

    React.useEffect(() => {
        fetch(`${endpoint}/simulations/${simulationId}`)
          .then(results => results.json())
          .then(data => {
            const bookingTimes = [];
            for(let key in data.times){
                if(key.length > 0) {
                    bookingTimes.push(key);
                }
            }
            setBookingTimesList(bookingTimes);
          });
      }, [bookingTime]);

    const options = bookingTimesList.map((bookingT) => {
        return <option value={bookingT} key={bookingT}>{bookingT}</option>
    });
    
    return (
        <div>
            <Panel>
            <PanelHeader>Simulation At Booking Time</PanelHeader>
            <PanelBody>
                <Label>Simulation ID:</Label>
                <InlineBlock>
                    <Label>{simulationId}</Label>
                </InlineBlock>
                <div className="gap"/>
                <Label>{isFetching ? "Loading" : "Select Booking Times"}</Label>
                <InlineBlock>
                    <form>
                        <select 
                            onChange={(event) => changeBookingTime(event.target.value)}
                            value={currentBookingTime}
                        >
                            {options}
                        </select>
                    </form>
                </InlineBlock>
            </PanelBody>
            </Panel>
        </div>
        );
 }

SimulationForm.propTypes = {
  fetchJobRequest: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  simulationId: PropTypes.string,
  bookingTime: PropTypes.string,
};

export default form(SimulationForm);
