import React from "react";
import styled from "styled-components";

const StyledInput = styled.input`
  display: block;
  width: 100%;
  height: 2.143em;
  font-size: 0.857em;
  padding: 0.571em 1em;
  border-radius: 0.143em;
  border: 0.071em solid #B8C4C9;
  box-shadow: inset 0 0.071em 0.071em rgba(0,0,0,0.075);
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  box-sizing: border-box;
  outline: none;
`;

const TextInput = props => (
  <StyledInput {...props} />
);

export default TextInput;
