import { all, fork } from "redux-saga/effects";
import watchJob from "./job";
import watchApp from "./app";

export default function* root() {
  yield all([
    fork(watchJob),
    fork(watchApp),
  ]);
}
