import React from "react";
import styled, { css } from "styled-components";

const StyledButton = styled.button`
  border-radius: 0.143em;
  border: none;
  background-color: #F6BE41;
  outline: none;
  font-size: 0.857em;
  font-family: Lato;
  font-weight: 700;
  color: #2D3639;
  padding: 0.371em 0.571em;
  height: 2.143em;
  cursor: pointer;
  ${({bStyle}) => bStyle === "outline-orange" && css`
    color: #F6BE41;
    border: 0.071em solid #F6BE41;
    background-color: transparent;
  `}
  ${({disabled}) => disabled && css`
    color: #B8C4C9;
    border: 0.071em solid #B8C4C9;
    background-color: #ECEFF1;
  `}
`;

const Button = ({title, ...props}) => (
  <StyledButton {...props} >{title}</StyledButton>
);

export default Button;
