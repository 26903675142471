import React, { PureComponent } from "react";
import styled from "styled-components";
import _ from "lodash";
import PropTypes from "prop-types";
import Fuse from "fuse.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSearch as fasSearch } from "@fortawesome/free-solid-svg-icons";
import TextInput from "./TextInput";
import { getTimeWindow } from "../helpers/location";

library.add(fasSearch);

const SearchContainer = styled.div`
  position: relative;
  svg {
    position: absolute;
    top: 9px;
    right: 10px;
    height: 12px;
    color: #B8C4C9;
  }
  input[type=text] {
    font-size: 12px;
    background: #FEFEFE;
    color: #56666C;
    box-shadow: 0 1px 5px 0 rgba(86,102,108,0.24);
    border-radius: 2px;
    border: none;
    width: 270px;
    line-height: 30px;
    height: 30px;
    padding: 0 10px;

    ::placeholder {
      color: #B8C4C9;
    }
  }
`;

const LocationsContainer = styled.div`
  margin-top: 10px;
  padding: 5px;
  background: #FEFEFE;
  box-shadow: 0 1px 5px 0 rgba(86,102,108,0.24);
  border-radius: 2px;
`;

const LocationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
  &:nth-child(odd) {
    background: rgba(89,156,245,0.15);
    border-radius: 2px;
  }
  &:hover {
    background: rgba(89,156,245,0.5);
  }
`;

const LocationIcon = styled.div`
  border-radius: 50%;
  border: 1px solid #fff;
  box-shadow: 0 1px 3px 0 rgba(86,102,108,0.50);
  text-align: center;
  margin: 10px 0 10px 10px;
`;

const LocationNumber = styled.span`
  display: block;
  width: 14px;
  height: 14px;
  text-align: center;
  font-family: Lato;
  font-size: 10px;
  line-height: 14px;
  color: ${({color}) => color || "#00B8D4"};
  background: #fff;
  border-radius: 50%;
  border: 2px solid ${({color}) => color || "#00B8D4"};
`;

const LocationInfo = styled.div`
  padding: 5px 10px;
  width: 230px;
  font-size: 12px;
  color: #56666C;
  p {
    margin: 2px 0;
  }
`;

const LocationInfoTop = styled.div`
  display: flex;
  justify-content: space-between;
  b.location-id {
    padding-right: 20px;
  }
  .vehicle-id {
    color: #599CF5;
  }
`;

class Search extends PureComponent {
  state = {
    keyword: "",
  }

  renderLocations = () => {
    const { keyword } = this.state;
    const locationsHTML = [];
    const locations = [];
    const searchOptions = {
      keys: ["location_id", "location_name", "name", "address", "type"],
    };
    let locationsCount = 0;

    if (!!keyword) {
      _.forEach(this.props.locations, (list) => {
        _.forEach(list, (location) => {
          locations.push(location);
        });
      });

      const fuse = new Fuse(locations, searchOptions);
      const filteredLocations = fuse.search(keyword);

      _.forEach(filteredLocations, (location) => {
        if (locationsCount >= 5) {
          return false;
        }

        if (location.location_id) {
          locationsHTML.push(
            <LocationContainer key={`${location.vehicleId}-${location.number}`} onClick={this.selectLocation(location)}>
              <LocationIcon color={`#${location.color}`}>
                <LocationNumber className="number" color={`#${location.color}`}>{location.number}</LocationNumber>
              </LocationIcon>
              <LocationInfo>
                <LocationInfoTop>
                  <b className="location-id">ID: {location.location_id} {location.type ?  `(${location.type})` : "" }</b>
                  <b>{getTimeWindow(location)}</b>
                </LocationInfoTop>
                {location.location_name && <p>{location.location_name}</p>}
                {location.address && <p>{location.address}</p>}
                <p className="vehicle-id">Vehicle: {location.vehicleId}</p>
              </LocationInfo>
            </LocationContainer>
          );
        }

        locationsCount = locationsCount + 1;
        return true;
      });
    }

    return (
      locationsHTML.length > 0 &&
      <LocationsContainer>
        { locationsHTML }
      </LocationsContainer>
    );
  }

  render() {
    const { keyword } = this.state;
    return (
      <SearchContainer>
        <form>
          <FontAwesomeIcon icon={"search"}/>
          <TextInput
            type="text"
            name="keyword"
            value={keyword}
            onChange={this.handleChange("keyword")}
            placeholder="Search for a stop"
          />
        </form>
        { this.renderLocations() }
      </SearchContainer>
    );
  }

  selectLocation = (location) => {
    return function open() {
      this.props.updateLocationState(location);
    }.bind(this);
  }

  handleChange = key => e => {
    this.setState({ [key]: e.target.value });
  }
}

Search.propTypes = {
  values: PropTypes.object,
  locations: PropTypes.object.isRequired,
  updateLocationState: PropTypes.func.isRequired,
};


export default Search;
