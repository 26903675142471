import { combineReducers } from "redux";
import app from "./app";
import jobReducer from "./job";
import filterReducer from "./filter";
import directionReducer from "./direction";
import mapReducer from "./map";

const reducers = combineReducers({
  app,
  jobReducer,
  filterReducer,
  directionReducer,
  mapReducer,
});

export default reducers;
