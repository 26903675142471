import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router";
import { BrowserRouter as Router } from "react-router-dom";
import PrivateRoute from "./containers/PrivateRoute";

import 'bootstrap/dist/css/bootstrap.css';
import 'jquery/dist/jquery.min.js'
import 'bootstrap/dist/js/bootstrap.min.js';
import './css/tailwind.css';
import "./app.css";

import App from "./components/App";
import LoginPage from "./pages/LoginPage";
import registerServiceWorker from "./registerServiceWorker";
import rootSaga from "./sagas";
import configureStore from "./config/store";
import configFonts from "./config/fonts";
import configFontAwesome from "./config/fontAwesome";

configFonts();
configFontAwesome();

const store = configureStore({});
store.runSaga(rootSaga);

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route exact path="/login" component={LoginPage} />
        <PrivateRoute exact path="/jobs/:id" component={App} />
        <PrivateRoute exact path="/simulation/:id/:bookingTime" component={App} />
        <PrivateRoute exact path="/" component={App} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);
registerServiceWorker();
