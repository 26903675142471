import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { css } from "styled-components";
import _ from "lodash";

import hexToRGB from "../helpers/hexToRGB";
import { getTimeWindow } from "../helpers/location";

const IconContainer = styled.div`
  position: absolute;
  top: 5px;
  cursor: pointer;
  box-shadow: 0 1px 3px 0 rgba(86,102,108,0.50);
  border-radius: 50%;
`;

const IconContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 21px;
  font-size: 7px;
  background-color: ${({color}) => hexToRGB(color, 0.8) || hexToRGB("gray", 0.8)};
  border: 1px solid ${({color}) => color || "gray"};
  text-align: center;
  border-radius: 50%;
  color: white;
`;

const LocationIcon = styled.div`
  position: absolute;
  border-radius: 50%;
  border: 1px solid #fff;
  box-shadow: 0 1px 3px 0 rgba(86,102,108,0.50);
  text-align: center;
`;

const LocationNumber = styled.span`
  display: flex;
  flex-wrap: nowrap;
  align-content: flex-start;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  text-align: center;
  font-family: Lato;
  font-size: 10px;
  line-height: 14px;
  color: ${({color}) => color || "#00B8D4"};
  background: #fff;
  border-radius: 50%;
  border: 2px solid ${({color}) => color || "#00B8D4"};
`;

const Box = styled.div`
  position: relative;
  margin-left: 1.857em;
  margin-top: ${({marginTop}) => marginTop || "0"};
  margin-bottom: ${({marginBottom}) => marginBottom || "0"};
  cursor: pointer;
`;

const BoxContent = styled.div`
  margin-left: 2px;
  padding: 5px 10px;
  ${({selected}) => selected && css`
    border-radius: 2px;
    background-color: ${({color}) => hexToRGB(color, 0.2) || hexToRGB("#00B8D4", 0.2)};
  `}
`;

const StopsCount = styled.span`
  float: right;
  display: inline-block;
  background: ${({color}) => color || "#00B8D4"};
  border-radius: 10px;
  font-size: 12px;
  color: #FFFFFF;
  text-align: center;
  min-width: 24px;
  margin-left: 15px;
`;

const BoxContainer = styled.div`
  margin-left: 0.714em;
  padding-left: ${({paddingLeft}) => paddingLeft || "9px"};
  ${({borderLeftColor}) => borderLeftColor && css`
    border-left: 0.071em dashed ${borderLeftColor};
  `}
`;

const Location = styled.div`
  ${({isSubContent}) => isSubContent && css`
    margin-left: 30px;
  `}
  .address {
    margin: 0.271em 0 0 0;
    font-size: 0.714em;
    font-family: Lato;
    color: #56666C;
  }
`;

const Badge = styled.span`
  padding: 0.286em 0.5em;
  border: 0.071em solid ${({color}) => color || "#00B8D4"};
  border-radius: 0.143em;
  font-size: 0.643em;
  font-family: Lato;
  font-weight: 700;
  cursor: pointer;
  color: ${({color}) => color || "#00B8D4"};
  background-color: ${({color}) => hexToRGB(color, 0.2) || hexToRGB("#00B8D4", 0.2)};
`;

const Title = styled.p`
  font-size: 0.714em;
  font-family: Lato;
  font-weight: 700;
  color: #56666C;
  margin: 0;
`;

// {false && <FontAwesomeIcon icon={["fa", "truck"]} />}
//         {false && <FontAwesomeIcon icon={["fa", "flag"]} />}
//         {false && <FontAwesomeIcon icon={["far", "circle"]} />}

// 1.857+0.714em
class Fleet extends PureComponent {
  state = {
    isToggled: false,
  }

  getDepotIcon(location) {
    if (location.vehicleId === "unserved" && location.isStartingLocation) {
      return (
        <IconContainer>
          <IconContent color={`#${location.color}`}>
            <FontAwesomeIcon icon={"times-circle"} style={{ fontSize: 12 }}/>
          </IconContent>
        </IconContainer>
      );
    }

    if (location.isStartingLocation) {
      return (
        <IconContainer>
          <IconContent color={`#${location.color}`}>
            <FontAwesomeIcon icon={"truck"} flip="horizontal"/>
          </IconContent>
        </IconContainer>
      );
    }

    if (location.isLastLocation) {
      return (
        <IconContainer>
          <IconContent color={`#${location.color}`}>
            <FontAwesomeIcon icon={"flag"}/>
          </IconContent>
        </IconContainer>
      );
    }

    return null;
  }

  getBorderLeftColor(location) {
    if (!location.isStartingLocation && !location.isLastLocation) return `#${location.color}`;
    return undefined;
  }

  getLocationIcon(location) {
    if (location.isStartingLocation || location.isLastLocation) {
      return null;
    }

    return (
      <LocationIcon color={`#${location.color}`}>
        <LocationNumber className="number" color={`#${location.color}`}>{location.number}</LocationNumber>
      </LocationIcon>
    );
  }

  getLocationTitle(location) {
    if (location.isStartingLocation) {
      return <Badge color={`#${location.color}`} onClick={this.selectLocation(location)} >{location.vehicleId !== "unserved" ? location.vehicleId : "Unserved Stops"}</Badge>;
    }

    if (location.isLastLocation) {
      return <Title>End Location</Title>;
    }
    const type = location.type ? `(${location.type})` : "";

    return <Title>{location.location_id} {type} <span style={{float: "right"}}>{getTimeWindow(location)}</span></Title>;
  }

  getLocations() {
    const { locations } = this.props;
    if (this.state.isToggled) return locations;

    return locations.slice(0, 1);
  }

  getLocationsCount() {
    const locations = this.props.locations || [];

    return _.filter(locations, { isVisit: true }).length;
  }

  render() {
    const locations = this.getLocations();

    return locations.map((location, i) =>
      <Box
        key={i}
        marginTop={location.isLastLocation ? "7px" : undefined}
        marginBottom={location.isStartingLocation ? "5px" : undefined}
        onClick={this.toggleLocations(location)}
      >
        {this.getDepotIcon(location)}
        <BoxContainer
          borderLeftColor={this.getBorderLeftColor(location)}
          onClick={this.selectLocation(location)}
        >
          <BoxContent color={`#${location.color}`} selected={this.checkSelected(location)}>
            { location.isStartingLocation &&
              <StopsCount color={`#${location.color}`}>{this.getLocationsCount()}</StopsCount>
            }
            {this.getLocationIcon(location)}
            <Location isSubContent={!location.isStartingLocation && !location.isLastLocation}>
              {this.getLocationTitle(location)}
              {location.location_name &&
                <p className="address">{location.location_name}</p>
              }
              {location.address &&
                <p className="address">{location.address}</p>
              }
            </Location>
          </BoxContent>
        </BoxContainer>
      </Box>
    );
  }

  selectLocation = (location) => {
    return function open() {
      if (!!location.lat) {
        this.props.updateLocationState(location);
      }
    }.bind(this);
  }

  toggleLocations = (location) => {
    return function toggle() {
      if (location.isStartingLocation) {
        this.setState({isToggled: !this.state.isToggled});
      }
    }.bind(this);
  }

  checkSelected(location) {
    const { currentLocation } = this.props;
    return (
      currentLocation &&
      currentLocation.lat === location.lat &&
      currentLocation.lng === location.lng &&
      currentLocation.location_id === location.location_id &&
      currentLocation.type === location.type &&
      currentLocation.number === location.number &&
      currentLocation.vehicleId === location.vehicleId
    );
  }
}

Fleet.propTypes = {
  locations: PropTypes.array.isRequired,
  updateLocationState: PropTypes.func.isRequired,
  currentLocation: PropTypes.object,
};

export default Fleet;
