import { createSelector } from "reselect";

const fleetIdsSelector = createSelector(
  (state) => state.jobReducer.data,
  (data) => {
    if (
      !data.input ||
      !data.input.fleet
    ) {
      return [];
    }
    const results = Object.keys(data.input.fleet);

    return [...results, "unserved"];
  }
);

export default fleetIdsSelector;
