export const getTimeWindow = (location) => {
  let timeWindow = "";

  if (location.arrival_time) {
    timeWindow = location.arrival_time;
  }
  if (location.finish_time) {
    timeWindow = location.finish_time;
  }
  if (location.arrival_time && location.finish_time) {
    timeWindow = `${location.arrival_time} - ${location.finish_time}`;
  }

  return timeWindow;
};

export const getNewLocationNearALocation = (location) => {
  const newLocation = location;

  if (location && location.lat && location.lng) {
    const earth = 6378.137; // radius of the earth in kilometer, could be moved to global constant
    const m = (1 / ((2 * Math.PI / 360) * earth)) / 1000; // 1 meter in degree
    const randomMeters = (Math.floor(Math.random() * (10 - 5 + 1) + 5)); // randome meter distance between 5 - 10

    newLocation.lat = location.lat + (randomMeters * m);
    newLocation.lng = location.lng + (randomMeters * m) / Math.cos(location.lat * (Math.PI / 180));
  }

  return newLocation;
};